import React from "react"

const TrustCost = () => {
  const title = `家族信託の費用`
  const content = `家族信託にかかる費用としては、公正証書作成費用、信託財産に不動産がある場合は不動産登記費用（登録免許税・司法書士報酬）、家族信託の専門家のコンサルティング費用等が考えられます。

`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．公正証書作成費用</dt>
            <dd>
              一般的に家族信託については、委託者と受託者の契約を公正証書を作成する方法によって行います。それは、対外的に一定の財産が信託財産であることを公示し、証明しやすくするためです。
              <br />
              また、信託された現金は、信託専用の銀行口座を作成して保管すべきですが、現状では、全ての銀行で信託専用の口座開設に応じてもらえるわけではありません。現在、信託専用口座の開設に応じている銀行のほとんどが、家族信託契約が、公正証書でなされていることが条件となっています。
              <br />
              公正証書の作成は公証役場で行いますが、公証人の手数料は信託する財産の価格により変わります。
            </dd>
            <dt>２．不動産登記費用</dt>
            <dd>
              不動産を信託した場合、委託者から受託者への所有権移転及び信託の登記をします。
              <br />
              その際に、登録免許税を納付しなければなりません。税率は固定資産税評価額の１,０００分の４（０.４
              ％）となります。
              <br />
              しかし、土地については、令和３年３月３１日までは、税率が１,０００分の３（０.３％）に軽減されています。
              <br />
              また、信託の登記を司法書士に依頼した場合に支払う報酬は、不動産の個数や、申請先の法務局の数などによって変わってきます。
            </dd>
            <dt>３．その他の費用</dt>
            <dd>
              その他の費用として、専門家へのコンサルティング費用や、公証役場へ提出する、銀行残高証明書や、不動産の登記事項証明書、固定資産税評価証明書等の取得費用等、細かな費用があります。
            </dd>
            <dt>４．費用の目安</dt>
            <dd>
              家族信託では、家族によって事情が代わり、契約条項や、信託する財産の多寡によって、費用に大きなさがありますので、必ず事前にお見積もりの依頼をするようにしましょう。
              <br />
              その際に、より正確な見積金額を得るために、信託する財産の内容と財産額、そして、不動産が含まれる場合は、不動産の固定資産税評価額の分かるものを用意するようにしてください。
              <br />
              そのようにすれば、よほど複雑な信託契約の内容でない限り、見積額と、実際の負担額が大きく異なることはないと思われます。
              <br />
              大まかな目安として、信託財産が３,０００万円程度で、不動産が含まれない場合、総額５０万〜６０万円程度、不動産が含まれる場合で、８０万円〜９０万円程度までになる方が多いと思われます。
              これは、あくまでの目安となりますので、詳しくは、お気軽にお問い合わせください。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default TrustCost
