import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import Kazokushintakutoha from "../components/familyTrustDetail/kazokushintakutoha"
import TrustHouse from "../components/familyTrustDetail/trustHouse"
import KazokushintakuTokucyo from "../components/familyTrustDetail/kazokushintakuTokucyo"
import TrustCost from "../components/familyTrustDetail/trustCost"
import BackContact from "../components/backContact"
import BlogLink from "../components/blogLink"

const FamilyTrust = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      familyTrust: file(relativePath: { eq: "family.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "相続対策や生前対策としての家族信託の仕組み、特徴、費用などについて、大阪の司法書士事務所の司法書士法人みつわ合同事務所がご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="家族信託　相続対策｜司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.familyTrust.childImageSharp.original.src}
        pageimgw={data.familyTrust.childImageSharp.original.width}
        pageimgh={data.familyTrust.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"familytrust"}
          fluid={data.familyTrust.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"家族信託"}
        />
        <section className="familytrust">
          <div className="familytrust__container">
            <h1 className="familytrust__title">家族信託</h1>
            <Kazokushintakutoha />
            <KazokushintakuTokucyo />
            <TrustHouse />
            <TrustCost />
            <BlogLink link={"trust"} linkName={"信託"} />
          </div>
        </section>
        <BackContact link={"/consul/"} linkName={"戻る"} />
      </div>
    </Layout>
  )
}

export default FamilyTrust
