import React from "react"

const Kazokushintakutoha = () => {
  const title = `家族信託とは？`
  const content = `信託とは、「自分の財産を、信頼できる人に託し、その財産を自分で決めた目的に従って、自分または自分の大切な人のためにその財産を管理・運用・処分してもらう」仕組みです。そして、その登場人物が家族内の人だけでなされているものを特に「家族信託」と呼んでいます。

  例えば、高齢の両親が、長男にお金を預けるとします。そのお金は両親の日常生活の費用に当てるためだけに使うという約束をします。
そして、子はその約束通り両親の食料や、生活必需品の購入にあてます。（この例は、厳密には信託契約と評価できませんが、説明を分かりやすくするためにご容赦ください。）

このような構造が家族信託です。この事例では、財産は金銭になります。信頼出来る人は長男、決められた目的は、日常生活の費用に当てるためだけに使う、そして、長男は、生活必需品を購入という決められた目的に沿った行為を行っています。
このケースで、家族信託の必要性をあまり感じることは無いと思われるでしょう。では、この仕組みの特徴と家族信託がどのような場合に効果を発揮するのかご紹介します。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  )
}

export default Kazokushintakutoha
