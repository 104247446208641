import React from "react"

const TrustHouse = () => {
  const title = `家族信託例：認知症対策`
  const content = `Ａは、体力の衰えを感じ、近いうちに介護施設に入所し、子供達（長女Ｂ・長男Ｃ）に迷惑をかけないようにしようと考えていた。
    そして、現在の自宅を売却し、介護施設の入所資金に当てることを考え、自宅の売却相場や、入所できる介護施設を調べていたところ、Ａは認知症を発症してしまった。
    上記のようなケースで、なんら対策をしていなかった場合と、家族信託を利用していた場合にどのような違いがあるのかをご紹介します。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．対策をしていなかった場合</dt>
            <dd>
              Ａは認知症の症状により、意思表示ができなくなり、不動産の売買契約を締結できなくなってしまったので、家庭裁判所に成年後見の申立をすることになった。
              <br />
              成年後見の審判が出るまで、申立から早くても１ヶ月かかることもあると言われ、自宅売却し、介護施設の入所資金が用立てることが出来るまで、長女Ｂと長男Ｃが交代で、Ａの身の回りの世話をすることになった。
              <br />
              １ヶ月後、成年後見の審判が出て、成年後見人として、長女Ｂが選任され、成年後見監督人として、専門家弁護士Ｄが選任された。
              <br />
              そこで、売却手続を進めたところ、買主が見つかったが、自宅の売却には、後見監督人の同意と、家庭裁判所の許可が必要とのことで、さらに売却許可決定が出るまで２週間の経過し、費用も発生した。
              <br />
              その後、自宅売却ができ、介護施設に無事入所できたが、手続き開始から、３ヶ月以上経過していた。
            </dd>
            <dt>２．家族信託を利用していた場合</dt>
            <dd>
              Ａは認知症の症状により、意思表示ができなくなったが、家族信託契約により、自宅の登記名義をＢに移転し、介護施設入所資金に使用するための自宅売却権限をあたえていたので、Ｂが単独で売却手続きをスムーズに進めることができた。
              <br />
              そして、ＡはＢに託していた不動産を現金化できたので、その資金により、介護施設に入所することができた。
              <br />
              （注：家族信託では施設入所契約（入所者本人が契約する必要がある場合）の代理人になることはできません。入所する施設の契約で代理権が必要な場合、任意後見契約等で法律上の代理権が別途必要となります。）
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default TrustHouse
