import React from "react"

const KazokushintakuTokucyo = () => {
  const title = `家族信託の特徴`
  const content = `信託は代理とは異なるものです。異なる点は、託した財産は自分のものでなくなるということです。また、託された人の名義人なるものの、託された人の固有の財産でもない、分別管理義務のある、いわゆる誰のための財産でもなくなります。このようにすることで、託された人に債権者がいたとしても、その債権者の引当て財産にならないこととされています。
    
  また、代理の場合、代理人は、本人のためにすることを示して契約等の法律行為を行いますが、信託の託された人は、当事者として契約等の法律行為を行います。このような点で、代理とは異なる制度となっています。
  
  他の特徴として、信託の中で定めることで、遺言書を作成したのと同様の財産承継の効果は持たせることができたり、遺言ではできない数世代に渡る財産承継ができたりなど、柔軟に財産の管理・運用・処分が可能となります。
  
  以下、信託における登場人物を簡単に説明します。
  
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．委託者</dt>
            <dd>財産を託す人のこと。信託の内容を決定する。</dd>
            <dt>２．受託者</dt>
            <dd>
              財産を託された人のこと。信託の目的に従って、信託財産の管理・運用・処分を行なう。
            </dd>
            <dt>３．受益者</dt>
            <dd>
              信託財産から利益を受ける権利（受益権）を持つ人。
              ほとんどのケースでは、贈与税の課税対象にならないように当初委託者が当初受益者となる。
            </dd>
            <dt>４．受益者代理人</dt>
            <dd>
              受益者の代理人となって、受益者の権利に関する一切の裁判上又は裁判外の行為をする権限を有する人。
              受益者の意思表示に不安がある場合に備えて信頼出来る人を選任しておくことが多い。
            </dd>
            <dt>５．信託監督人</dt>
            <dd>
              信託の目的に従い、信託財産の管理等が行われているかを受益者に代わって監視・監督する人。
              家族信託では、専門家等を選任し、実務の運用についてアドバイスを求める立場の人が欲しいと言う場合に選任されることがあります。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default KazokushintakuTokucyo
